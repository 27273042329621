import { useEffect, useState } from "react";

const user = process.env.REACT_APP_USER;
const password = process.env.REACT_APP_PASS;

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const data = {
      username: user,
      password: password,
    };
    const authOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch("/api/v1/auth/login", authOptions)
      .then((response) => response.json())
      .then((data) => {
        const { access_token } = data.detail;

        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        };

        window.setTimeout(() => {
          fetch("/api/v1/clients/list", options)
            .then((response) => response.json())
            .then((data) => setData(data.detail))
            .catch((error) => console.error(error));
        }, 3000);
      })
      .catch((error) => console.error(error));
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div>
    test v2.1
      data loading result:
      <br />
      {data.length ? (
        data.map((item) => <div key={item.id}>{JSON.stringify(item)}</div>)
      ) : (
        <div>No Data</div>
      )}
    </div>
  );
};

export default App;
